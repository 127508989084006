import React, { useEffect, useState } from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import ReadingBlogPost from '../components/ReadingBlogPost'
import TagFilter from '../components/TagFilter';
import Seo from '../components/seo';

const createTagsArray = (data) => {
  let tags = [];
  // data create an array
  data.readings.nodes.forEach(node => {
    const tagArray = node.frontmatter.post_tags;
  
    if (tagArray) {
      tagArray.forEach(tag => {
        if(!tags.includes(tag)) {
          tags.push(tag);
        }
      });
    }
  });
  return tags;
}


const PaginatedReadingPage = ({data, pageContext}) => {
  const [tags, setTags] = useState([]);

  const readings = data.readings.nodes;

  // Create the tags array before component mounts
  useEffect(() => {
    setTags(createTagsArray(data));
  }, [data])

  console.log(pageContext)
  //Tags to pass to filter
    const tagis = pageContext.tags;
//   //Build pagination nav
//   const currentPage = pageContext.currentPage
//   const numPages = pageContext.numPages
//   const isFirst = currentPage === 1
//   const isLast = currentPage === numPages
//   const prevPage = currentPage - 1 === 1 ? "/further-reading" : (currentPage - 1).toString()
//   const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <Seo title="Further Reading"/>
      <div className="container mainBlogPage">
        <div className="row">
            <div className="col">
            <h2>Further Reading</h2>
            </div>
            <TagFilter tagsArray={tagis} />
        </div>
        <div className="row">
          {readings.map(reading => (<ReadingBlogPost key={reading.frontmatter.slug} reading={reading} />))}
        </div>
        {/* <div className="pagination">
            {!isFirst && (
                <Link to={prevPage} rel="prev">←</Link>
            )}
            {!isLast && (
                <Link to={nextPage} rel="next">→</Link>
            )}
        </div> */}
      </div>
    </Layout>
  )
}

export default PaginatedReadingPage

export const query = graphql`
query {
  readings: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/further-reading-posts/"}}
    sort: {fields: frontmatter___date_of_post, order: DESC}
    ) {
     nodes {
       frontmatter {
         post_headline
         post_subtitle
         post_tags
         feat_image_toggle
         featured_image {
           childImageSharp {
             gatsbyImageData(layout: FULL_WIDTH)
           }
         }
         slug
       }
       html
       excerpt(pruneLength: 70)
     }
   }
 }
 `;